<template>
  <div>
    <addField
      :path="path"
      :additionalAttributes="{
        updateTable: true,
        function: 'getInsertedFieldsUpdateSelect',
        updatedField: this.field,
        updatedDialogRef: updatedDialogRef,
      }"
      :dialogAttributes="fieldAttributes['addingDialogOptions']"
      v-on="$listeners"
    >
       <v-icon title="Add" class="ml-1" :id="id + '_adding_button'">mdi-plus-box-outline</v-icon>
    </addField>
  </div>
</template>
<script>
//import getConnectionObject from "@/components/getConnectionObject.vue";
import { getSubType, getPageCat } from "@/js/testHelpers.js";
import addField from "@/commonComponents/addField.vue";
export default {
  props: ["result", "fieldAttributes", "field", "updatedDialogRef","id"],

  //props: ["result", "fieldAttributes", "sentUrl", "field", "updatedDialogRef"],
  components: { addField },
  methods: {
    created() {
      console.log(this.result);
    },
  },
  computed: {
    path() {
      let type = this.result.stepType;
      let c_subType = getSubType(type, this.result.stepStoredValue);
      if (c_subType == "PING") {
        c_subType = "SSH";
      }
      // let c_subType = this.result.subType;
      let p = getPageCat(this.result.stepStoredValue);
      let path =
        this.fieldAttributes.addingURL +
        "&p=" +
        p +
        "&c_subType=" +
        c_subType +
        "&c_type=" +
        type;
      return path;
    },
  },
};

//   function addConnection(caller,url,addditionalAttributes){
//     let $caller=$(caller);
//     var $fieldsObject = $caller.closest(".fields");
//     let $step = $caller.closest(".step");
//     let type=$step.attr("data-application-type");
//      var $systemId=$fieldsObject.find(".systemId");
//     var systemIdValue = $systemId.val();
//     //let $stepTypeSelector=$step.find(".stepTypeSelector");
//     let subType = $step.attr("data-subtype");
//     let category = $step.attr("data-category");

//     let   attributes=$.extend({c_type:type,n_systemId:systemIdValue,"c_subType":subType,"type":subType,"p":category},addditionalAttributes);
//     return AddFieldUpdateSelect(caller,"serve.php?f=configuration&f2=outboundConnections&f3=nocsConnection&p="+encodeURIComponent(category)+"&c_subType="+subType+"&c_subType="+subType+"&c_type="+type,attributes );
//   }
</script>